import axios from "axios";
import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import {
  ApiPermissaoUsrNaAplicacao,
  PermissaoUsrNaAplicacao,
} from "./entidades/permissao";
import { UsrId, Usuario } from "./entidades/usuario";

export class UsuarioLogado implements Usuario {
  id: UsrId = 0;
  pessoa_id: number = 0;
  cpf: string = "";
  login: string = "";
  nome: string = "";
  foto: string = "";
  permissoes: PermissaoUsrNaAplicacao[] = [];
  email: string = "";
}

type TipoUsuarioLogado = UsuarioLogado | undefined | null;

let globalSetUsuarioLogado = (usr?: UsuarioLogado) => {};

const useUsuarioLogadoImpl = () => {
  const [usr, setUsr] = useState<TipoUsuarioLogado>(undefined);
  globalSetUsuarioLogado = setUsr;
  useEffect(() => {
    setTimeout(() => setUsr(null), 0);
  }, []);

  return usr;
};

export const useUsuarioLogado = singletonHook(undefined, useUsuarioLogadoImpl);
export const setUsuarioLogado = (
  usr: Usuario | UsuarioLogado | undefined | null,
  token?: string
) => {
  if (!!usr) {
    if (!!token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["token"] = token;
    }
    ApiPermissaoUsrNaAplicacao.get_do_usuario(usr.cpf).then((permissoes) => {
      globalSetUsuarioLogado({ ...usr, permissoes });
    });
  } else {
    // logout
    globalSetUsuarioLogado(undefined);
    delete axios.defaults.headers.common["token"];
    localStorage.removeItem("token");
    window.location.reload();
  }
};
